<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <loading-card v-if="isLoading" />
      <div
        v-else
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <h1 class="text-medium">
            Laba Hasil Produksi
          </h1>
          <div class="uk-margin-top wrapper-content">
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Total Hasil Produksi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div 
                  class="uk-input currency-field uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.total_production)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.total_production"
                    class="uk-input"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.total_production)"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Total Biaya Sapronak
              </div>
              <div class="uk-width-3-5 set-style">
                <div class="uk-input label-red currency-field uk-text-bold">
                  <span>- Rp</span>
                  <input
                    v-model="formData.rhpp_production_profit_sharing.total_cost_of_sapronak"
                    class="uk-width-2-3 uk-input label-red uk-text-bold"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Total Retur Barang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div 
                  class="uk-input currency-field uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.total_return_of_goods)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.total_return_of_goods"
                    class="uk-input"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.total_return_of_goods)"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Total Biaya Operasional (Biops)
              </div>
              <div class="uk-width-3-5 set-style">
                <div class="uk-input label-red currency-field uk-text-bold">
                  <span>- Rp</span>
                  <input
                    v-model="formData.rhpp_production_profit_sharing.total_operational_cost"
                    class="uk-width-2-3 uk-input label-red uk-text-bold"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small bottom-field"
              uk-grid
            >
              <div
                class="gr-txt uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style"
              >
                Laba Hasil Produksi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div 
                  class="uk-input currency-field uk-text-bold set-bottom-style"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.total_production_profit)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.total_production_profit"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.total_production_profit)"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <h1 class="text-medium">
            Perhitungan Bagi Hasil
          </h1>
          <!-- card start -->
          <div class="uk-margin-top wrapper-content">
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Perhitungan Bagi Hasil Mitra ({{ formData.rhpp_production_profit_sharing.sharing_percentage ? formData.rhpp_production_profit_sharing.sharing_percentage : 60 }}%)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div 
                  class="uk-input currency-field uk-text-bold uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.calculation_profit_sharing_partner)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.calculation_profit_sharing_partner"
                    class="uk-input"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.calculation_profit_sharing_partner)"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Subsidi Asisten Mitra
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div 
                  class="uk-input currency-field uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.partner_assistant_subsidy)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.partner_assistant_subsidy"
                    class="uk-input"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.partner_assistant_subsidy)"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Subsidi Biaya Mitra
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div 
                  class="uk-input currency-field uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.partner_cost_subsidy)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.partner_cost_subsidy"
                    class="uk-input"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.partner_cost_subsidy)"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small bottom-field"
              uk-grid
            >
              <div
                class="gr-txt uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style"
              >
                Bagi Hasil Mitra + Subsidi Biaya
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div 
                  class="uk-input currency-field uk-text-bold set-bottom-style uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.partner_profit_sharing_plus_subsidy_cost)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.partner_profit_sharing_plus_subsidy_cost"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.partner_profit_sharing_plus_subsidy_cost)"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- card start -->
          <div class="uk-margin-top wrapper-content">
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Pengembalian Biaya Operasional <br>(PBO)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div 
                  class="uk-input currency-field uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.operational_cost_refund)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.operational_cost_refund"
                    class="uk-input"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.operational_cost_refund)"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Pendapatan Kotor Mitra
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div
                  class="uk-input currency-field uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.gross_income_partner)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.gross_income_partner"
                    class="uk-input"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.gross_income_partner)"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                PBO + Pendapatan Mitra Kotor
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div
                  class="uk-input currency-field uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.ocr_plus_gip)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.ocr_plus_gip"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.ocr_plus_gip)"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-2-5 field-style">
                Pajak Penghasilan PPH {{ formData.rhpp_production_profit_sharing.income_tax_farm_label }} ({{ formData.rhpp_production_profit_sharing.income_tax_farm }}%)
              </div>
              <div class="uk-width-3-5 set-style">
                <div class="uk-input currency-field uk-text-bold label-red uk-text-bold">
                  <span>- Rp</span>
                  <input
                    v-model="formData.rhpp_production_profit_sharing.income_tax"
                    class="uk-width-2-3 uk-input label-red uk-text-bold"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Cicilan Dana
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div
                  class="uk-input currency-field uk-text-bold"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.installment_amount)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.installment_amount"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.installment_amount)"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                Penyelesaian RHPP Sebelumnya
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div class="uk-input currency-field uk-text-bold">
                  <div class="uk-inline">
                    <span
                      :class="{
                        'uk-form-icon': true,
                        'uk-text-bold': true,
                        'label-green': formData.rhpp.seperator_previous_rhpp_solution == 1,
                        'label-red': formData.rhpp.seperator_previous_rhpp_solution == 0
                      }"
                    >
                      {{ formData.rhpp.seperator_previous_rhpp_solution == 1 ? '+ Rp' : '- Rp' }}
                    </span>
                    <input
                      v-model="formData.rhpp_production_profit_sharing.previous_rhpp_solution"
                      :class="{
                        'uk-width-2-3': true,
                        'uk-input': true,
                        'uk-text-bold': true,
                        'label-green': formData.rhpp.seperator_previous_rhpp_solution == 1,
                        'label-red': formData.rhpp.seperator_previous_rhpp_solution == 0
                      }"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              style="border-bottom: 1px solid #C2D6CE;"
              class="uk-child-width-expand@s uk-grid-small bottom-field"
              uk-grid
            >
              <div
                class="gr-txt uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style"
              >
                Pendapatan Mitra Setelah Pajak
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div
                  class="uk-input currency-field uk-text-bold set-bottom-style"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.partner_net_income)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.partner_net_income"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.partner_net_income)"
                    class="gr-txt uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small bottom-field"
              uk-grid
            >
              <div
                class="gr-txt uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style"
              >
                Pendapatan Mitra Setelah Pajak per ekor
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                <div
                  class="uk-input currency-field uk-text-bold set-bottom-style"
                  :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.gross_income_per_head)"
                >
                  Rp
                  <input
                    v-model="formData.rhpp_production_profit_sharing.gross_income_per_head"
                    :class="setLabelColor(detailRhpp.rhpp_production_profit_sharing.gross_income_per_head)"
                    class="gr-txt uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import LoadingCard from '@/components/globals/loading'
import { thousandSeparator, float0Decimals } from '@/utils/formater'

export default {
  components: {
    LoadingCard
  },
  props: {
    detailRhpp: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      formData: {
        rhpp: {
          seperator_previous_rhpp_solution: null
        },
        rhpp_production_profit_sharing: {}
      }
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  watch: {
    detailRhpp() {
      if (this.detailRhpp) {
        this.isLoading = false
        const dataRhpp = this.detailRhpp
        this.formData = {
          ...dataRhpp,
          rhpp_production_profit_sharing: {
            ...dataRhpp.rhpp_production_profit_sharing,
            total_production: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.total_production),
            total_cost_of_sapronak: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.total_cost_of_sapronak),
            total_return_of_goods: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.total_return_of_goods),
            total_production_profit: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.total_production_profit),
            gross_income: thousandSeparator(float0Decimals(dataRhpp.rhpp_production_profit_sharing.gross_income)),
            income_tax: thousandSeparator(float0Decimals(dataRhpp.rhpp_production_profit_sharing.income_tax)),
            installment_amount: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.installment_amount),
            previous_rhpp_solution: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.previous_rhpp_solution),
            partner_net_income: thousandSeparator(float0Decimals(dataRhpp.rhpp_production_profit_sharing.partner_net_income)),
            gross_income_per_head: thousandSeparator(float0Decimals(dataRhpp.rhpp_production_profit_sharing.gross_income_per_head)),
            subsidy: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.subsidy),
            total_operational_cost: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.total_operational_cost),
            calculation_profit_sharing_partner: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.calculation_profit_sharing_partner),
            partner_assistant_subsidy: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.partner_assistant_subsidy),
            partner_cost_subsidy: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.partner_cost_subsidy),
            partner_profit_sharing_plus_subsidy_cost: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.partner_profit_sharing_plus_subsidy_cost),
            operational_cost_refund: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.operational_cost_refund),
            gross_income_partner: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.gross_income_partner),
            ocr_plus_gip: thousandSeparator(dataRhpp.rhpp_production_profit_sharing.ocr_plus_gip)

          }
        }
      }
      this.isLoading = false
    }
  },
  async mounted() {
  },
  methods: {
    setLabelColor(field){
      return field < 0 ? 're-text' : 'gr-text'
    },
    ...mapActions({
    }),
    ...mapMutations({
    })
  }
}
</script>

<style scoped>
.gr-txt{
  color: #025231 !important;
}

.re-text{
  color: #F92626 !important;
}

h1 {
  color: #1F2E28;
  font-size: 14px;
}
.wrapper-content {
  border: 1px solid #C2D6CE;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
}
.field-style {
  display: flex;
  align-items: center;
  padding-left: 16px;
  color: #1F2E28;
  font-size: 14px !important;
}
.uk-input {
  color: #1F2E28;
  border: none;
  background: none;
}
.currency-field {
  overflow: hidden;
  display: flex;
  align-items: center;
  color: #1F2E28;
}
.wrapper-field {
  height: 58px !important;
  margin-top: 0px;
  margin-left: 0;
  border-bottom: 1px solid #c2d6ce73;
}
.bottom-field {
  height: 58px !important;
  margin-top: 0px;
  margin-left: 0;
  background: #F0F5F3;
}
.set-style {
  display: flex;
  align-items: center;
}
.set-bottom-style {
  color: #025231;
}
</style>
